import React, { useState, useEffect, useContext } from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import styles from "./index.module.css"
import socialmediaBranding from "../images/socialmediabranding.jpg"
import computerImage from "../images/computerImage.jpeg"
import seoImage from "../images/seoImage.jpg"
import webDevsWorking from "../images/webDevsWorking.jpg"
import teamMembers from "../images/team-members.jpg"
import NavigationContext from "../components/navigationContext/NavigationContext"
import mobileDevices from "../images/mobile-devices.jpg"

export default () => {
  const [fadeInWebDev, setFadeInWebDev] = useState(null)
  const [fadeInDataManagement, setFadeInDataManagement] = useState(null)
  const [fadeInSeo, setFadeInSeo] = useState(null)
  const [fadeInMobile, setFadeInMobile] = useState(null)
  const [fadeInSocial, setFadeInSocial] = useState(null)
  const [fadeInBranding, setFadeInBranding] = useState(null)

  const navigationContext = useContext(NavigationContext)

  const handleSetFadeInItems = (entries, observer) => {
    entries.forEach((entry, index) => {
      if (entry.target.id === "web-dev-image")
        if (entry.isIntersecting) setFadeInWebDev(true)
      if (entry.target.id === "data-management")
        if (entry.isIntersecting) setFadeInDataManagement(true)
      if (entry.target.id === "seo")
        if (entry.isIntersecting) setFadeInSeo(true)
      if (entry.target.id === "branding")
        if (entry.isIntersecting) setFadeInBranding(true)
      if (entry.target.id === "social")
        if (entry.isIntersecting) setFadeInSocial(true)
      if (entry.target.id === "mobile")
        if (entry.isIntersecting) setFadeInMobile(true)
    })
  }

  useEffect(() => {
    let observer = new IntersectionObserver(handleSetFadeInItems, {
      root: null,
      rootMargin: "0px",
      threashold: 1.0,
    })
    observer.observe(document.getElementById("web-dev-image"))
    observer.observe(document.getElementById("data-management"))
    observer.observe(document.getElementById("seo"))
    observer.observe(document.getElementById("mobile"))
    observer.observe(document.getElementById("social"))
    observer.observe(document.getElementById("branding"))
  }, [])

  console.log(navigationContext)
  return (
    <Layout>
      <main
        id="main"
        className="grid grid-auto-rows grid-cols-3 w-full h-full relative"
      >

        <div
          id="web-dev-image"
          className="col-start-1 col-span-3 lg:col-span-2 row-start-1 lg:row-start-1 row-span-1 relative"
          style={{ backgroundColor: "rgb(42, 47, 74)" }}
        >
          <div
            style={{ width: "100%", height: "320px" }}
            className={`${
              fadeInWebDev
                ? "animate__animated animate__fadeInLeft"
                : "opacity-0"
            }`}
          >
            <div
              className={`absolute text-white p-16 text-3xl w-full z-20 ${styles.menuText} `}
            >
              <h2>Web Development</h2>
              <hr className=""></hr>
              <p className="text-lg">
                From design to deployment and everywhere between.
              </p>
              <Link
                to="/WebDev#main"
                className={`p-1 px-2 mt-2 border text-xl absolute z-20 inline ${styles.menuText}`}
              >
                Learn More{" "}
              </Link>
            </div>
            <div className={`${styles.filter} z-10`}></div>
            <img
              src={webDevsWorking}
              className="absolute w-full h-full object-cover z-0"
            ></img>
          </div>
        </div>

        <div
          id='data-management'
          className="col-start-1 lg:col-start-3 col-span-3 lg:col-span-1 row-start-3 lg:row-start-1 row-span-1 relative"
          style={{ backgroundColor: "rgb(42, 47, 74)" }}
        >
          <div
            style={{ width: "100%", height: "320px" }}
            className={`${
              fadeInDataManagement
                ? "animate__animated animate__fadeInRight"
                : "opacity-0"
            }`}
          >
            <div
              className={`absolute text-white p-16 text-3xl w-full z-20 ${styles.dataManagementMenuText} `}
            >
              <h2>
                Data Management
                </h2>
              <hr className=""></hr>
              <p className="text-lg"> Gain insight about your customers.</p>
              <Link
                to="/Data#main"
                className={`p-1 px-2 mt-2 border text-xl absolute z-20 inline ${styles.menuText}`}
              >
                Learn More{" "}
              </Link>
            </div>
            <div className={`${styles.tealFilter} z-10`}></div>
            <img
              src={computerImage}
              id="web-dev-image"
              className="w-full h-full z-0 object-cover"
            />
          </div>
        </div>

        <div
        id='seo'
          className="col-start-1 col-span-3 lg:col-span-1 row-start-2 lg:row-start-2 row-span-1 relative"
          style={{ backgroundColor: "rgb(46, 52, 80)" }}
        >
          <div
            style={{ width: "100%", height: "320px" }}
            className={`w-full h-full ${
              fadeInSeo ? "animate__animated animate__fadeInLeft" : "opacity-0"
            }`}
          >
            <p
              className={`absolute text-white p-16 text-3xl w-full z-20 ${styles.seoMenuText} `}
            >
              {" "}
              <h2>SEO</h2>
              <hr className=""></hr>
              <p className="text-lg">Top of the search results, every time.</p>
              <Link
                to="/Seo#main"
                className={`p-1 px-2 mt-2 border text-xl absolute z-20 inline ${styles.menuText}`}
              >
                Learn More{" "}
              </Link>
            </p>
            <div className={`${styles.orangeFilter} z-10`}></div>
            <img
              className=" w-full h-full absolute z-0 object-cover"
              src={seoImage}
              id="seo-image"
            />
          </div>
        </div>

        <div
        id='mobile'
          className="col-start-1 lg:col-start-2 col-span-3 lg:col-span-2 row-start-4 lg:row-start-2 row-span-1 relative"
          style={{ backgroundColor: "	rgb(46, 52, 80)" }}
        >
          <div
            style={{ width: "100%", height: "320px" }}
            className={`w-full h-full ${
              fadeInMobile ? "animate__animated animate__fadeInRight" : "opacity-0"
            }`}
          >
            <p
              className={`absolute text-white p-16 text-3xl w-full z-20 ${styles.mobileMenuText} `}
            >
              {" "}
              <h2>Mobile Applications</h2>
              <hr className=""></hr>
              <p className="text-lg">
                Endless Possibilities for Android and IOS.
              </p>
              <Link
                to="/Mobile#main"
                className={`p-1 px-2 mt-2 border text-xl absolute z-20 inline ${styles.menuText}`}
              >
                Learn More{" "}
              </Link>
            </p>
            <div className={`${styles.redFilter} z-10`}></div>
            <img
              src={mobileDevices}
              className="w-full h-full absolute z-0 object-cover"
            ></img>
          </div>
        </div>

        <div
          id='social'
          className="col-start-1 col-span-3 lg:col-span-2 row-start-6 lg:row-start-3 row-span-1 relative"
          style={{ backgroundColor: "rgb(42, 47, 74)" }}
        >
          <div
            style={{ width: "100%", height: "320px" }}
            className={` w-full h-full ${
              fadeInSocial
                ? "animate__animated animate__fadeInLeft "
                : "opacity-0"
            }`}
          >
            <p
              className={`absolute text-white p-16 text-3xl w-full z-20 ${styles.socialMediaMenuText} `}
            >
              {" "}
              <h2>Social Media</h2>
              <hr className=""></hr>
              <p className="text-lg">
                Connect with billions of potential customers, now.
              </p>
              <Link
                to="/Social#main"
                className={`p-1 px-2 mt-2 border text-xl absolute z-20 inline ${styles.menuText}`}
              >
                Learn More{" "}
              </Link>
            </p>
            <div className={`${styles.greenFilter} z-10`}></div>
            <img
              src={teamMembers}
              className="w-full h-full z-0 object-cover"
            ></img>
          </div>
        </div>

        <div
          id='branding'
          className="col-start-1 lg:col-start-3 col-span-3 lg:col-span-1 row-start-5 lg:row-start-3 row-span-1 relative"
          style={{ backgroundColor: "rgb(42, 47, 74)" }}
        >
          <div
            style={{ width: "100%", height: "320px" }}
            className={`w-full h-full ${
              fadeInBranding
                ? "animate__animated animate__fadeInRight "
                : "opacity-0"
            }`}
          >
            <p
              className={`absolute text-white p-16 text-3xl w-full z-20 ${styles.menuText} `}
            >
              {" "}
              <h2>Branding</h2>
              <hr className=""></hr>
              <p className="text-lg">Make an unforgettable impression.</p>
              <Link
                to="/Branding#main"
                className={`p-1 px-2 mt-2 border text-xl absolute z-20 inline ${styles.menuText}`}
              >
                Learn More{" "}
              </Link>
            </p>

            <div className={`${styles.filter} z-10`}></div>
            <img
              id="marketing-image"
              src={socialmediaBranding}
              className="z-0 w-full h-full object-cover"
            />
          </div>
        </div>
      </main>
    </Layout>
  )
}
